.download-page {
   display: flex;
   justify-content: center;
   font-family: "Almoni";

   .header {
      background-color: #FC5726;
      width: 390px;
      height: 74px;

      img {
         float: left;
         margin: 21px 0 0 20px;

      }
   }

   .title {
      display: flex;
      justify-content: center;

      p {
         font-size: 32px;
         font-weight: bold;
         color: black;
         width: 316px;
         margin: 4px 0 4px 0;
         line-height: 1;
         letter-spacing: 0.2px;
      }
   }

   .text {
      display: flex;
      justify-content: center;
      direction: rtl;

      p {
         font-size: 20px;
         font-weight: bold;
         color: black;
         width: 340px;
         margin: 4px 0 0px 0;
         text-align: right;
      }
   }

   .list {
      padding: 0 24px;

      .list-item {
         direction: rtl;
         display: flex;
         justify-content: right;

         .dot {
            background-color: red;
            width: 8px;
            height: 8px;
            margin: 8px 0px 0 8px;
            border-radius: 3px;
            color: #FC5726;
         }
      }
   }

   .download-text {
      font-size: 32px;
      font-weight: bold;
      color: black;
   }

   .btn-wrapper {
      display: flex;
      justify-content: space-around;
      cursor: pointer;
   }

}